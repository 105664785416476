/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./node_modules/normalize.css/normalize.css"
import "./src/assets/fonts/fonts.css"
import "./src/assets/style.scss"
